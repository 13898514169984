<template>
  <div class="">

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'Banking'">
      <template v-slot:right-end>
      </template>
    </breadcrumb-base>
    <div class="flex-row items-end flex justify-end ">

      <vs-button class="ml-4 mb-3" @click="viewAllData()">{{ viewAll ? $t('ShowLess') : $t('ViewAll') }}</vs-button>
    </div>
    <!--    Build Table from records Data-->
    <div class="table-responsive w-full">

      <vs-table-modified v-show="records.length > 0" ref="table"
                         :data="records">
        <template slot="thead">
          <vs-th >{{$t('Customer')}}</vs-th>
          <vs-th >{{$t('Address')}}</vs-th>
          <vs-th >
            <div class="vs-col">
              <p class="">{{ $t('City') }}</p>
              <p class="">{{ $t('PostalCode') }}</p>
            </div>
          </vs-th>
          <vs-th >{{$t('RecipientSender')}}</vs-th>
          <vs-th >{{$t('Actions')}}</vs-th>
        </template>
        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="records" :key="indextr" v-for="(record, indextr) in data">

            <vs-td>
              <div class="vs-col">
                <p>{{ record.customer_id || '-' }}</p>
                <p>{{ record.name }}</p>
              </div>
            </vs-td>
            <vs-td>
              <p class="font-medium truncate" style="font-size: 12px;">
                {{ record.address || '-' }}<span v-if="record.house_no"> {{ record.house_no }}</span>
              </p>
            </vs-td>
            <vs-td>
              <div class="vs-col">
                <p class="font-medium truncate">{{ record.city || '-' }}</p>
                <p class="font-medium truncate">{{ record.postal_code }}</p>
              </div>
            </vs-td>
            <vs-td>
              <p class="font-medium truncate">{{ record.recipient_sender }}</p>
            </vs-td>
            <vs-td>
              <div class="flex flex-row w-full items-center justify-center space-x-2">
                <vs-button color="success" class="px-2 py-1"
                           @click="selectRecordForEdit(record)"
                           icon="edit">{{ $t("Edit") }}</vs-button>
                <vs-button color="danger" class="px-2 py-1"
                           @click="selectRecordForEdit(record, true)"
                           icon="delete">{{ $t("Unmap") }}</vs-button>
              </div>
            </vs-td>

          </vs-tr>
          </tbody>
        </template>
        <template slot="footer-left">
          <div class="flex">
            <vs-new-pagination :total="vTotalPages" v-model="cPage" v-on:change="listPageChanged">
            </vs-new-pagination>
          </div>
        </template>
      </vs-table-modified>
    </div>
    <div>
      <vx-modal v-if="selectedRecord" @close="onCloseMapCustomerDialog" component-class="modal-container-7 banking-custom-height">
        <template slot="header"></template>
        <template slot="body" >
          <div class="flex flex-col p-6 justify-center items-center">
            <p style="font-size: 20px;" class="pb-6">
              <span>{{ $t("MapCustomer") }}</span>
              <strong>
                {{ selectedRecord.recipient_sender }}
              </strong>
            </p>
            <dynamic-customers-drop-down
              :show-clear="false"
              :placeholder="$t('SearchCustomer')"
              :selected-item="selectedCustomer || {}"
              @on-item-selected="onCustomerChanged">
            </dynamic-customers-drop-down>
            <div class="vs-row flex justify-center px-4 mt-6">
              <vs-button size="large" class="ml-4 mr-4" color="danger" @click="onCloseMapCustomerDialog()">{{
                  $t('Close')
                }}
              </vs-button>
              <vs-button size="large" class="ml-2" color="primary" @click="mapCustomer()">{{ $t('MapCustomer') }}</vs-button>
            </div>
          </div>
        </template>
        <template #footer></template>
      </vx-modal>
    </div>
  </div>
</template>

<script>
import DynamicCustomersDropDown from '@/views/pages/superadmin/customer/DynamicCustomersDropDown.vue'
import VxModal from "@/layouts/components/custom/VxModal";

export default {
  name: "BankingMappedCustomers",
  components: {
    DynamicCustomersDropDown,
    VxModal,
  },
  data() {
    return {
      viewAll: false,
      selectedRecord: null,
      selectedCustomer: null,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Banking', i18n: 'Banking', active: true },
      ],
      cPage: 1,
    };
  },
  computed: {
    records() {
      return this.$store.state.banking.mappedRecords;
    },
    vPage() {
      return this.$store.state.banking.vPage
    },
    vTotalPages() {
      return this.$store.state.banking.vTotalPages
    },
  },
  methods: {
    listPageChanged() {
      this.fetchRecords(this.cPage - 1)
    },
    viewAllData() {
      this.viewAll = !this.viewAll;

      this.fetchRecords()
    },
    onCloseMapCustomerDialog() {
      this.selectedRecord = null;
      this.selectedCustomer = null
    },
    onCustomerChanged(c) {
      this.selectedCustomer = c
    },
    selectRecordForEdit(record, isDelete = false) {
      if(isDelete) {
        this.deleteCustomer(record);
      } else {
        this.selectedRecord = record;
        this.selectedCustomer = null
      }
    },
    deleteCustomer(record) {
      let payload = {
        "bank_name_map_id": record.bank_name_map_id,
      }
      this.$vs.loading()
      this.$store.dispatch('banking/deleteMappedCustomer', payload).then((data) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.fetchRecords();
        this.onCloseMapCustomerDialog();
        // this.$router.push({ name: 'banking-list' })
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      })
    },
    mapCustomer() {
      let payload = {
        "customer_id": this.selectedCustomer.customer_id,
        "bank_record_id": this.selectedRecord.bank_record_id,
      }
      this.$vs.loading()
      this.$store.dispatch('banking/mapCustomer', payload).then((data) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.fetchRecords();
        this.onCloseMapCustomerDialog();
        // this.$router.push({ name: 'banking-list' })
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      })
    },
    async fetchRecords(page = '0') {
      this.$vs.loading()
      let payload = {
        "page": page,
      }
      if(this.viewAll) {
        payload['view_all'] = this.viewAll ? 1 : 0;
      }
      try {
        await this.$store.dispatch('banking/fetchBankMappedCustomers', payload);
      } catch (e) {
        console.log(e);
      }
      this.$vs.loading.close();
    },
  },
  mounted() {
    this.fetchRecords();
  }
}
</script>

<style >
.banking-custom-height {
  height: 700px;
}
</style>
